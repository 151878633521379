import React from 'react';
import { Helmet } from 'react-helmet';
import Layout from '../components/Layout';
import SafeLink from '../components/SafeLink';
import DiffTaichi_Liquid from '../images/branding/difftaichi_liquid.gif';

/**
 * The 404 page.
 */
export default function NotFoundPage() {
  return (
    <Layout>
      <Helmet>
        <title>404 Not found</title>
        <meta name="404" content="Taichi Graphics 404 Page" />
      </Helmet>

      <main className="overflow-hidden">
        <div className="py-36 lg:py-48">
          <div className="relative z-10 max-w-7xl mx-auto pl-4 pr-8 sm:px-6 lg:px-8 space-y-20">
            <h1 className="text-center text-3xl font-extrabold tracking-tight text-warm-gray-700 sm:text-5xl lg:text-6xl">
              Failed to trace the path of light
            </h1>

            <img
              className="relative mx-auto filter blur-lg hover:blur-0"
              src={DiffTaichi_Liquid}
              alt=""
            />

            <p className="text-center font-semibold text-warm-gray-700">
              Sorry, the page you are looking for is not found.
              {' '}
              <br />
              You can go back to the
              {' '}
              <SafeLink isExternal to="/" className="text-indigo-500">
                Home Page
              </SafeLink>
              {' '}
              or check out other resouces on our website!
            </p>
          </div>
        </div>
      </main>
    </Layout>
  );
}
